import { localLogger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// login user
export async function login(
  email,
  password,
  browserInfo, // optional
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    apiValidateArguments({ email, password, adminToken });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/get-auth/login`,
      "POST",
      {
        email,
        password,
        ...(browserInfo && { system_info: { info: browserInfo } }),
      },
      { Authorization: adminToken },
      false,
      false
    );

    localLogger("Response in login function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in login function:", error);
    throw error;
  }
}

// get token to login user on frontend at end of OAuth process
export async function getOAuthFirebaseToken(
  provider,
  browserInfo, // optional
  adminToken = process.env.NEXT_PUBLIC_PROJECT_ID
) {
  try {
    // Validate args
    apiValidateArguments({ adminToken, provider });

    // Make call to server
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/global/get-auth/oauth_user`,
      "POST",
      { ...(browserInfo && { system_info: { info: browserInfo } }), provider },
      { Authorization: adminToken },
      true,
      false
    );

    // Return custom response data
    localLogger("Response in getOAuthFirebaseToken function:", response);

    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getOAuthFirebaseToken function:", error);
    throw error;
  }
}

// logout user
export async function logout(accessToken) {
  try {
    // Validate args
    if (!accessToken) throw new Error("Missing access token.");

    // Make call to server
    const response = await apiRequestExternal(
      `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/user_session/logout`,
      "PUT",
      null,
      { Authorization: accessToken },
      false,
      true
    );

    // Clear localStorage of midway new experiment data
    localStorage.removeItem("activeStep");
    localStorage.removeItem("collectedData");

    // Return custom response data
    localLogger("Response in logout function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in logout function:", error);
    throw error;
  }
}

// export async function getSessions(accessToken) {
//   try {
//     // Validate args
//     if (!accessToken) throw new Error("Missing access token.");

//     // Return custom response data
//     const response = await apiRequestExternal(
//       `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/public/regional/auth/user/users/session/open`,
//       "GET",
//       null,
//       { Authorization: accessToken }
//     );

//     localLogger("Response in getSessions function:", response);
//     return response;

//     // Handle errors
//   } catch (error) {
//     console.error("Error in getSessions function:", error);
//     throw error;
//   }
// }
