// Custom middleware to simplify API calls

// Example use:
// const response = await apiRequestExternal(
//     `${process.env.NEXT_PUBLIC_COMPOSER_URL}/v1/example/query?example=example`, // url
//     'POST', // method
//     { key1: 'value1' }, // body
//     { Authorization: yourToken } // headers
//   );

import config from "../config";
import { auth } from "../util/auth";
import { updateBadges } from "../util/badgeUpdater";
import { localLogger, logger } from "../util/logger";
import { updateSnackbar } from "../util/snackbarUpdater";
import {
  incrementFailureCount,
  resetFailureCount,
} from "./apiRequestFailureTracker";

// Refresh auth token
const getNewToken = async () => {
  logger("trying token refresh");
  localLogger("auth", auth);
  localLogger("auth.user:", auth.user);
  localLogger("auth.currentUser:", auth.currentUser);
  const currentUser = auth.currentUser || auth.user;
  let idToken;
  if (currentUser) {
    idToken = await currentUser.getIdToken(true); // Force token refresh
    logger("refreshed ID token");
    localLogger("idToken:", idToken);
    const reloadedUser = await currentUser.reload();
    logger("reloaded user");
    localLogger("reloadedUser:", reloadedUser);
  } else {
    console.error("No current user found for token refresh.");
  }
  return idToken;
};

export async function apiRequestExternal(
  url,
  method = "GET",
  body = null,
  customHeaders = {},
  includeCredentials = false,
  shouldLog = true,
  timesToRetry = 0,
  retriedWithNewToken = false
) {
  const controller = new AbortController();
  const signal = controller.signal;
  let timeoutId;

  try {
    // Request timeout tracking
    timeoutId = setTimeout(() => {
      controller.abort();
    }, config.timeoutSeconds * 1000);
    // let requestCompleted = false;

    // Conditionally log arguments
    if (process.env.NEXT_PUBLIC_NODE_ENV === "local") {
      localLogger(
        "args:",
        url,
        method,
        body,
        customHeaders,
        includeCredentials
      );
    } else if (shouldLog) {
      logger(
        "args:",
        url,
        method,
        body,
        "customHeaders omitted",
        includeCredentials
      );
    }

    // Set default headers combined with any custom headers passed
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      firebase_access_token: "NrSfirebase_AcesS_toKen",
      ...customHeaders,
    };

    // Make call, including any body if not a GET
    const response = await fetch(url, {
      method: method,
      headers: headers,
      credentials: includeCredentials ? "include" : "omit",
      body: method !== "GET" ? JSON.stringify(body) : undefined,
      signal: signal,
    });

    clearTimeout(timeoutId);

    // const fetchPromise = fetch(url, {
    //   method: method,
    //   headers: headers,
    //   credentials: includeCredentials ? "include" : "omit",
    //   ...(body && method !== "GET" && { body: JSON.stringify(body) }),
    // });

    // Timeout promise
    // setTimeout(() => {
    //   if (!requestCompleted) {
    //     incrementFailureCount();
    //     throw new Error(
    //       `Request timed out after ${config.timeoutSeconds} seconds.`
    //     );
    //   }
    // }, config.timeoutSeconds * 1000);

    // const response = await fetchPromise;
    // requestCompleted = true;

    // if (shouldLog) {
    //   logger("Raw composer response:", response);
    // } else {
    //   localLogger("Raw composer response:", response);
    // }
    // Parse response body
    const responseBody = await response.text();

    // if (shouldLog) {
    //   logger("responseBody:", responseBody);
    // } else {
    //   localLogger("responseBody:", responseBody);
    // }

    const data = responseBody ? JSON.parse(responseBody) : null;

    if (data && data.badges) {
      updateBadges(data.badges);
    }
    // updateBadges([
    //   {
    //     id: 1,
    //     name: "First Steps",
    //     description: "Incredible amazing steps that are indeed first!",
    //     img_url: "https://storage.googleapis.com/nrs_badges/first-steps.png",
    //     img_alt_text: "Man running up books like steps",
    //     earned_date: "Jan 31, 2021",
    //   },
    // ]);

    // Throw error if composer response is not ok
    if (!response.ok) {
      // Handle 507
      if (response.status === 507) {
        console.error("Server overloaded.");
        updateSnackbar({
          message: "Server overloaded. Please try again soon.",
          severity: "error",
        });
      } else if (response.status >= 500 && response.status <= 599) {
        // Handle other 500s
        if (timesToRetry > 0) {
          console.error(`Got 500. Retrying in 3 seconds.`);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          return apiRequestExternal(
            url,
            method,
            body,
            customHeaders,
            includeCredentials,
            shouldLog,
            timesToRetry - 1
          );
        } else {
          incrementFailureCount();
        }
      } else if (response.status === 401) {
        // Handle 401
        // Refresh Firebase token & retry just 1x if unauthorized error
        console.error("401...");
        if (!retriedWithNewToken) {
          const token = await getNewToken();
          if (token) {
            return apiRequestExternal(
              url,
              method,
              body,
              { ...customHeaders, Authorization: token },
              includeCredentials,
              shouldLog,
              timesToRetry,
              true
            );
          }
        } else {
          logger("Retried with new token, still 401. Logging out of Firebase.");
          auth?.signOut();
        }
        // Handle other errors
      } else if (response.status) {
        resetFailureCount();
      }
      // Throw error for all non-OK status codes
      const error = new Error(`Server response not OK.`);
      error.status = response.status;
      error.body = responseBody ? JSON.parse(responseBody) : null;
      throw error;
    }

    // Reset failure count if successful
    resetFailureCount();

    // Return a useful response object
    return {
      data: data,
      status: response.status,
    };
  } catch (err) {
    clearTimeout(timeoutId);
    // Handle major server/network errors
    if (err instanceof TypeError) {
      if (err.message.includes("NetworkError")) {
        console.error(
          "Network error. This might be due to offline status or DNS failure."
        );
      } else {
        console.error(
          "Possible CORS or unexpected network issue:",
          err.message
        );
      }
      incrementFailureCount();
    }

    if (err.name === "AbortError") {
      err.status = 408;
      console.error(`Request timed out after ${config.timeoutSeconds} seconds`);
      incrementFailureCount();
    }

    // Handle errors
    logger(err);
    err.status && console.error(`Error with status ${err.status} occurred.`);
    err.message && logger("Error message:", err.message);
    err.body && logger("Body:", err.body);
    err.response && logger("Response:", err.response);
    logger("Full error object:", JSON.stringify(err, null, 2));
    throw err;
  }
}
